<template>
	<div class="prepublic-stage">
		<div class="main-column">
			<div class="content sole-serif">
				<div class="header">
					<h1 class="text-center rotunda-veneta">Life Beware Herald</h1>
					
					<hr>
					
					<div class="date">{{date}}</div>
				</div>
					
				<hr>
				
				<div class="message text-center">
					<div class="medium-font">
						Il sindaco indice un referendum!
						<br><br>
						Unisciti alla tua associazione per decidere cosa votare e convincere gli altri cittadini di Life Beware Valley.
					</div>
					
					<hr>
					
					<div v-if="superuser" class="row-padding">I giocatori si stanno unendo al proprio team.<br>Quando sono pronti premi <strong class="red-txt">CONTINUA</strong></div>
				</div>
				
				
				<div v-if="!superuser" class="group text-center row-padding">
					<div>Il tuo gruppo è il n°{{ group[0].work_group}}{{ group[0].public_group}}</div>
					<div>Unisciti al tuo team, il tuo gruppo è costituito da:</div>
					
					<div class="large-font row-padding">
						<div class="group-user" v-for="(user, i) in group" :key="i">
							{{user.name}}
						</div>
					</div>
				</div>
				
				<div v-if="superuser" class="message text-center">
					<div class="button arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Prework',
		mixins : [helperMixin, gameMixin],
		components: {
			
		},
	}
</script>	

<style lang="scss">
	.prepublic-stage{
		h2{
			font-size: 20px;
		}
	}
</style>