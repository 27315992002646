<template>
	<div class="publicview-stage">
		<div class="main-column">
			<div class="content fulmar">
				<div v-if="superuser">
					<timer-manager />
				</div>
				
				<div v-else>
					<div class="header">
						<h1 class="text-center">Life Beware Bank</h1>
						
						
						<h2 class="text-center medium-font">REFERENDUM CITTADINO</h2>
						
						<hr>
					</div>
					
					<div class="message text-center">
						<div class="medium-font">Partecipa alle decisioni di Life Beware!</div>
						<hr>
						<div>Discuti con la tua associazione e scegliete quale è secondo voi l’azione più adeguata da votare. Dovrete convincere anche gli altri giocatori nella prossima fase.</div>
					</div>
					
					<hr>
					
					<choice-manager
						:has_leader="false"
						:inputs="false"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimerManager from '@/components/TimerManager.vue'
	import ChoiceManager from '@/components/ChoiceManager.vue'
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Publicview',
		mixins : [helperMixin, gameMixin],
		components: {
			TimerManager, ChoiceManager
		},
	}
</script>	

<style lang="scss">
	.publicview-stage{
		h2{
			font-size: 20px;
			white-space: nowrap;
		}
	}
</style>