
import axios from 'axios';
	
export const gameMixin = {
	data() {
		return {
			advancing_phase : false,
		};
	},
	computed: {
		date(){
			var date = new Date();
			var day = date.toLocaleDateString('it-IT', { weekday: 'long' });
			var month = date.toLocaleString('it-IT', { month: 'long' });
			return day+', '+date.getDate()+' '+month+' '+date.getFullYear(); 
		},
		disable_choice(){
			return (this.timer.end - this.current_time < 0 && this.timer.pause == null);
		}
	},
	methods : {
		advancePhase(){
			this.advancing_phase = true;
			
			var data = { 
				user_id : this.user_id,  
				match_id : this.match_id 
			};
			
			axios.post(this.api_url+'advance_phase', data)
				.then( (response) => {	
					if(response.status == 200 && response.data.success){
						this.$store.commit('setStage', response.data.message.stage);
						this.$store.commit('setDisaster', response.data.message.data);
					}
					
					this.advancing_phase = false;
				});
		},
	}
}