<template>
	<div class="publicdiscuss-stage">
		<div class="main-column">
			<div class="content fulmar">
				<div class="header">
					<h1 class="text-center">Life Beware Bank</h1>
					
					
					<h2 class="text-center medium-font">REFERENDUM CITTADINO</h2>
					
					<hr>
				</div>
				
				<div class="message text-center row-padding">
					<div v-if="superuser" class="row-padding">
						I giocatori a turno presentano la loro posizione rispetto al referendum.
						<br><br>
						Quando tutti i giocatori hanno disccusso la loro posizione premi <strong class="red-txt">CONTINUA</strong> per passare al voto.
					</div>
					
					<div v-else class="medium-font row-padding">
						È il momento che la tua associazione presenti la sua scelta.
						<br><br>
						Convinci gli altri giocatori della bontà di una scelta rispetto ad altre.
					</div>
				</div>
				
				<div v-if="superuser" class="message text-center">
					<div class="button arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Publicdiscuss',
		mixins : [helperMixin, gameMixin],
		components: {
			
		},
	}
</script>	

<style lang="scss">
	.publicdiscuss-stage{
		h2{
			font-size: 20px;
			white-space: nowrap;
		}
	}
</style>