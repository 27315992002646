<template>
	<div class="timer-manager macho-moustache">
		<div class="main-column">
			<div class="wrapper">
				<div v-if="stage != 'second_turn_publicdiscuss' || stage != 'fourth_turn_publicdiscuss'">
					<div class="intro text-center">PUOI AVVIARE IL TIMER DA QUI APPENA I GIOCATORI SONO PRONTI. NEL CASO CE NE FOSSE BISOGNO, PUOI METTERLO IN PAUSA O RIAVVIARLO</div>
				
					<div class="timer text-center row-padding">
						<div class="huge-font" v-if="!disable_choice">
							<span v-if="timer.pause == null">{{ readableTime((timer.end ) - current_time) }}</span>
							<span v-else>{{ readableTime(timer.end - timer.pause ) }} (Pausa)</span>
						</div>
						
						<div class="large-font" v-else>
							TIMER SCADUTO
						</div>
					</div>
					
					<div class="actions flex space">
						<div class="button white" @click="pauseTimer" :class="{disabled : changing_timer}">{{ timer.pause == null ? 'Pausa' : 'Avvia'}}</div>
						<div class="button white" @click="restartTimer" :class="{disabled : changing_timer}">Riavvia</div>
					</div>
				</div>
				
				<div v-else>
					<div class="intro text-center uppercase">I giocatori adesso possono discutere le loro scelte con l'associazione</div>
				</div>
				
				<div class="continue">
					<div class="button white arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
				
				<div class="users-status row-padding">
					<div class="text-center medium-font">Status votazione</div>
					<div class="choice-users" v-if="phase_component  == 'personal'">
						<div v-for="(user, i) in choice_users" class="user flex space" :key="i">
							<div class="name">{{user.name}}</div>
							<div class="vote">{{user.choice != 0 ? 'sì' : 'no'}}</div>
						</div>
					</div>
					
					<div class="work-groups" v-if="phase_component  == 'work'">
						<div v-for="(user, i) in work_groups" class="user flex space" :key="i">
							<div class="name">Gruppo {{user.work_group}}</div>
							<div class="vote">{{user.choice != 0 ? 'sì' : 'no'}}</div>
						</div>
					</div>
					
					<div class="public-groups" v-if="phase_component  == 'publicvote'">
						<div v-for="(user, i) in choice_users" class="user flex space" :key="i">
							<div class="name">{{user.name}}</div>
							<div class="vote">{{user.choice != 0 ? 'sì' : 'no'}}</div>
						</div>
					</div>	
				</div>	
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';
	import axios from 'axios';

	export default {
		name: 'TimerManager',
		mixins : [helperMixin, gameMixin],
		data() {
			return {
				changing_timer : false,
			};
		},
		computed: {
			phase_component(){
				var component = this.stage;
				
				component = component.replace('first_turn_', '');
				component = component.replace('second_turn_', '');
				component = component.replace('third_turn_', '');
				component = component.replace('fourth_turn_', '');
				
				component = component.replace('first_', '');
				component = component.replace('last_', '');
				
				return component
			},
			work_groups(){
				return this.choice_users.slice().sort(function(a,b){
					return a.work_group < b.work_group ? -1 : 1;
				});
			},
		},
		methods : {
			pauseTimer(){
				this.changing_timer = true;
				var action = this.timer.pause == null ? 'pause_timer' : 'resume_timer';
				
				var data = { 
					user_id : this.user_id,  
					match_id : this.match_id 
				}
				
				axios.post(this.api_url+action, data)
					.then( (response) => {	
						if(response.status == 200 && response.data.success){
							if(action == 'pause_timer'){
								this.$store.commit('setTimerPause', response.data.message);
							}else{
								this.$store.commit('setTimerEnd', response.data.message);
								this.$store.commit('setTimerPause', null);
							}
							this.changing_timer = false;
						}
					});
			},
			restartTimer(){
				this.changing_timer = true;
				
				var turn = 1;
				var type = 'personal';
				
				if(this.stage.includes('second')) turn = 2;
				if(this.stage.includes('third')) turn = 3;
				if(this.stage.includes('fourth')) turn = 4;
				
				if(this.stage.includes('work')) type = 'work';
				if(this.stage.includes('publicview')) type = 'publicview';
				if(this.stage.includes('publicvote')) type = 'publicvote';
				
				var data = { 
					user_id : this.user_id,  
					match_id : this.match_id,
					turn : turn,
					type : type
				}
				
				axios.post(this.api_url+'restart_timer', data)
					.then( (response) => {	
						if(response.status == 200 && response.data.success){
							this.$store.commit('setTimerEnd', response.data.message.timer);
							this.$store.commit('setTimerPause', response.data.message.pause);
							this.changing_timer = false;
						}
					});
			},
		},
	}
</script>	

<style lang="scss">
	@import "@/assets/styles/variables.scss";

	.timer-manager{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
		color: white;
		z-index: 1;
		
		.wrapper{
			padding: 25px;
		}
		
		.timer{
			line-height: 1;
		}
		
		.continue{
			margin-top: 20px;
		}
		
		.users-status .user:first-child{
			margin-top: 10px;
		}
	}
</style>