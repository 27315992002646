<template>
	<div class="game-end-stage">
		<div class="main-column">
			<div class="content sole-serif">
				<div class="header">
					<h1 class="text-center rotunda-veneta">Life Beware Herald</h1>
					
					<hr>
					
					<div class="date">{{date}}</div>
				</div>
				
				<hr>
				
				<div class="row-padding medium-font text-center">
					<div v-if="end_condition == 'special'">
						Complimenti! Avete portato tutte le risorse oltre il 50%
						<br><br>
						<strong>Life Beware Valley è salva!</strong>
						<br><br>
						Nonostante l’ultima catastrofe le nostre risorse sono state sufficienti per garantire il benessere di tutti.
						<br><br>
						Continua così, ogni piccola azione è importante per aiutare a migliorare l’ecosistema!
					</div>
					
					<div v-if="end_condition == 'victory'">
						Siete riusciti a portare le risorse pubbliche oltre il 50%
						<br><br>
						<strong>Life Beware Valley è salva!</strong>
						<br><br>
						Nonostante l’ultima catastrofe le nostre risorse sono state sufficienti per garantire il benessere di tutti.
						<br><br>
						Continua così, ogni piccola azione è importante per aiutare a migliorare l’ecosistema!
					</div>
					
					<div v-if="end_condition == 'failure'">
						Life Beware Valley non ce l’ha fatta!
						<br><br>
						Purtroppo non abbiamo fatto abbastanza e le nostre risorse non sono state sufficienti a salvarci dall’ultima catastrofe.
						<br><br>
						Ma puoi imparare dai tuoi errori e ritentare con una nuova partita!
					</div>
				</div>
				
				<div class="button" @click="resetMatch">Nuova partita</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'GameEnd',
		mixins : [helperMixin, gameMixin],
		computed: {
			end_condition(){
				var successes = 0;
				if(parseFloat(this.public_board.hydraulic_risk_reduction_level) >= 50) successes++; 
				if(parseFloat(this.public_board.ecosystem_services) 			>= 50) successes++; 
				if(parseFloat(this.public_board.short_economic_availability) 	>= 50) successes++; 
				if(parseFloat(this.public_board.long_economic_availability) 	>= 50) successes++; 
				if(parseFloat(this.public_board.citizens_wellbeing_perception) 	>= 50) successes++; 
				
				if(successes == 5) return 'special';
				if(parseFloat(this.public_board.hydraulic_risk_reduction_level) >= 50) 	return 'victory';
				
				return 'failure';
			}
		},
		methods: {
			resetMatch(){
				this.$store.commit('resetMatch');
				window.location.reload();
			}
		},
	}
</script>	

<style lang="scss">
	.game-end-stage{
		
	}
</style>