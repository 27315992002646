<template>
	<div class="boards small-col-padding macho-moustache" :class="{disaster : stage == 'first_disaster' || stage == 'last_disaster'}">
		<div class="text text-center">
			<div v-if="!disaster_stage">Queste le nostre risorse per il futuro</div>
			<div v-else>
				Grossi danni alle strutture,<br>
				in crisi <span v-html="boardValueNameWithArticle(worst_disaster)"></span>
			</div>
		</div>
		
		<div v-if="!superuser">
			<select v-model="current_board">
				<option value="public">Pubblica</option>
				<option value="personal">Personale</option>
				<option value="work">Lavorativa</option>
			</select>
		</div>
		
		<div class="value first">
			<div class="bar">
				<div class="segments">
					<div v-for="n in 5" class="segment" :key="n" :style="{left : (n-1)*25+'%'}">
						<div class="label">{{(n-1)*25}}%</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="value" v-for="(value, key) in board" :key="key">
			<div class="name"><img :src="require('@/assets/icon_'+key+'.svg')"/>{{boardValueName(key)}}</div>
			
			<div class="bar">
				<div class="segments">
					<div v-for="n in 5" class="segment" :key="n" :style="{left : (n-1)*25+'%'}"></div>
				</div>
				<div class="inner" :style="{width: disaster_stage ? (Math.min(100,parseInt(value)) + parseInt(disaster[key]))+'%' : Math.min(100,value)+'%'}">
					<div class="label bold">{{ Math.round(disaster_stage ? parseInt(value) + parseInt(disaster[key]) : parseInt(value))}}%</div>
				</div>
				<div class="reduction" 
					v-if="stage == 'first_disaster' || stage == 'last_disaster'"
					:style="{
						left: (Math.min(100,parseInt(value)) + parseInt(disaster[key]))+'%',
						width: Math.abs(disaster[key])+'%'
					}"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';

	export default {
		name: 'Boards',
		mixins : [helperMixin],
		data() {
			return {
				current_board : 'public',
			};
		},
		computed: {
			board(){
				return this[this.current_board+'_board'];
			},
			disaster_stage(){
				return this.stage == 'first_disaster' || this.stage == 'last_disaster';
			},
			worst_disaster(){
				var keys = [
					'citizens_wellbeing_perception',
					'ecosystem_services',
					'hydraulic_risk_reduction_level',
					'long_economic_availability',
					'short_economic_availability',
				];
				
				var worst = {
					key : '',
					value : 0,
				};
				
				keys.forEach(function(key){
					var value = parseInt(this.disaster[key]);
					if(value < worst.value){
						worst.key = key;
						worst.value = value;
					}
				}.bind(this));
				
				
				return worst.key;
			}
		},
		methods : {
			getImage(path) {
				return require(path)
			}
		},
	}
</script>	

<style lang="scss">
	@import "@/assets/styles/variables.scss";


	.boards{
		.text{
			margin: 20px 0;
		}
		
		select{
			font-weight: bold;
		}
		
		.value{
			margin-bottom: 25px;
			
			&.first{
				margin-top: 30px;
			}
			
			.name{
				margin-bottom: 5px;
				white-space: nowrap;
				display: flex;
				
				img{
					margin-right: 5px;
				}
			}
			
			.bar{
				position: relative;
				height: 25px;
				
				.segments{
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					
					.segment{
						position: absolute;
						top: 0;
						height: 100%;
						border-left: 2px solid black;
						z-index: 1;
						
						.label{
							position: absolute;
							bottom: 100%;
							transform: translateX(-50%);
							margin-bottom: 3px;
						}
					}
				}
				
				.inner{
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background-color: $gray;
					
					.label{
						position: absolute;
						left: 100%;
						top: 50%;
						transform: translate(-50%, -50%);
						margin-left: 5px;
						background-color: white;
						z-index: 1;
						font-size: 12px;
						padding: 0 2px;
					}
				}
				
				.reduction{
					position: absolute;
					top: 0;
					height: 100%;
					background-color: $red;
				}
			}
		}
		
		&.disaster .inner .label{
			color: $red;
		}
	}
</style>