<template>
	<div class="game-screen" :data-stage="stage" :data-phase="phase_component">
		<div class="game">  
			<transition name="slide">
				<component 
					:is="phase_component"
				></component>
			</transition>	
			
			<!--
			<div class="reset-button" @click="resetMatch">
				<span></span>
				<span></span>
			</div>
			-->
		</div>
	</div>
</template>

<script>
	import Beginning from '@/components/phases/Beginning.vue'
	import Disaster from '@/components/phases/Disaster.vue'
	import Personal from '@/components/phases/Personal.vue'
	import Postpersonal from '@/components/phases/Postpersonal.vue'
	import Prework from '@/components/phases/Prework.vue'
	import Work from '@/components/phases/Work.vue'
	import Postwork from '@/components/phases/Postwork.vue'
	import End from '@/components/phases/End.vue'
	import Prepublic from '@/components/phases/Prepublic.vue'
	import Publicview from '@/components/phases/Publicview.vue'
	import Publicdiscuss from '@/components/phases/Publicdiscuss.vue'
	import Publicvote from '@/components/phases/Publicvote.vue'
	import Postpublic from '@/components/phases/Postpublic.vue'
	import Game_end from '@/components/phases/GameEnd.vue'
	import { helperMixin } from '@/store/helper.js';

	export default {
		name: 'Game',
		mixins : [helperMixin],
		components: {
			Beginning, Disaster, Personal, Postpersonal, Prework, Work, Postwork, End, Prepublic, Publicview, Publicdiscuss, Publicvote, Postpublic, Game_end
		},
		computed: {
			phase_component(){
				var component = this.stage;
				
				component = component.replace('first_turn_', '');
				component = component.replace('second_turn_', '');
				component = component.replace('third_turn_', '');
				component = component.replace('fourth_turn_', '');
				
				component = component.replace('first_', '');
				component = component.replace('last_', '');
				
				return component
			},
		},
		methods: {
			resetMatch(){
				if(confirm('Si desidera abbandonare la partita corrente ed iniziarne una nuova?')){
					this.$store.commit('resetMatch');
					window.location.reload();
				}
			}
		},
	}
</script>	

<style lang="scss">
	@import "../assets/styles/variables.scss";
	
	.game-screen{
		position: relative;
		width: 100%;
		min-height: 100vh;
		background: $lightgray;
		overflow-x: hidden;
		display: flex;
		align-items: center;
		transition: background 0.3s;
		
		&[data-phase=personal],
		&[data-phase=postpersonal]{
			background: #36775B;
		}
		&[data-phase=prework],
		&[data-phase=work],
		&[data-phase=postwork]{
			background: #4A519E;
		}
		&[data-phase=prepublic],
		&[data-phase=publicview],
		&[data-phase=publicdiscuss],
		&[data-phase=publicvote],
		&[data-phase=postpublic]{
			background: #2AB4AB;
		}
		
		.main-column{
			min-height: 100%;
			display: flex;
			align-items: center;
		}
		
		.game{  
			width: 100%;
			
			> div{
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				opacity: 1;
				transition: all 0.7s;
			}
			
			.content{
				position: relative;
				background: #ffffff;
				border-radius: 30px;
				padding: 50px 25px;
				margin: 25px 0;
				margin-bottom: 75px;
				opacity: 1;
				transition: all 1s;
				width: 100%;
			}
			
			.header{
				&.colored{
					background: #4DA880;
					margin-top: -50px;
					margin-left: -25px;
					margin-right: -25px;
					padding-top: 50px;
					padding-left: 25px;
					padding-right: 25px;
					padding-bottom: 10px;
					margin-bottom: 10px;
					border-top-left-radius: 30px;
					border-top-right-radius: 30px;
				}
				
				.date{
					text-transform: capitalize;
				}
			}
			
			.reset-button{
				position: absolute;
				left: 0;
				top: 100%;
				margin-top: 20px;
				background-color: red;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				cursor: pointer;
				
				span{
					position: absolute;
					left: 6px;
					top: 15px;
					width: 20px;
					height: 2px;
					background-color: white;
					transform: rotate(45deg);
					
					&:last-child{
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	
	
	.slide-enter-from{
		opacity: 0 !important;
		left: 100% !important;
	} 
	.slide-leave-to{
		opacity: 0 !important;
		left: -100% !important;
	}
</style>