<template>
	<div class="postpersonal-stage">
		<div class="main-column">
			<div class="content fulmar">
				<div class="header">
					<h1 class="text-center">Life Beware Bank</h1>
					
					<h2 class="text-center medium-font">REFERENDUM CITTADINO</h2>
				</div>
				
				<hr>
				
				<div class="message text-center">
					<div v-if="superuser">
						<div>Qui puoi vedere a quale risultato hanno portato le azioni dei giocatori.Premi <strong class="red-txt">CONTINUA</strong> per avanzare alla prossima fase.</div>		
					</div>
					
					<div v-else>
						<div> Qui puoi vedere a quale risultato ha portato la tua azione e quelle degli altri giocatore. Guarda qui come sono cambiate le statistiche private e pubbliche.</div>
					</div>
				</div>
				
				<hr>
				
				<boards/>
				
				<div v-if="superuser" class="message text-center">
					<div class="button arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Boards from '@/components/Boards.vue'
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Postwork',
		mixins : [helperMixin, gameMixin],
		components: {
			Boards
		},
	}
</script>	

<style lang="scss">
	.postwork-stage{
		
	}
</style>