<template>
	<div class="choice-manager small-row-padding macho-moustache">
		<div v-if="has_leader">
			<div class="text-center" v-if="not_leader">In questa occasione solo il leader dovrà esprimere un voto sentendo il parere di tutto il gruppo</div>
			<div class="text-center" v-else>Solo tu potrai esprimere il voto sentito il parere del tuo gruppo</div>
		</div>
		<div class="text-center" v-else>Scegli una tra le seguenti azioni:</div>
		
		<div class="choices" :class="{'not-leader' : not_leader}" v-if="current_choice">
			<div class="choice" v-for="(choice, index) in current_choice.choices" :key="index" :class="{toggled : toggled_choice == choice.ID}">
				<div class="flex v-center">
					<input v-if="inputs && !not_leader" type="radio" v-model="selected_choice" :value="choice.ID">
						
					<label class="flex v-center" @click="toggleChoice(choice.ID)">
						<div class="text">
							<div class="name bold">{{choice.name}}</div>
						</div>
					</label>
					<div class="toggle" @click="toggleChoice(choice.ID)"><div></div></div>
				</div>
				<div class="toggled-content" v-if="toggled_choice == choice.ID">
					<div class="level flex space v-center">
						<div>Riduzione rischio idraulico</div>
						<div class="small-font value">{{ boardLevelName(choice.hydraulic_risk_reduction_level) }}</div>
					</div>
					<div class="level flex space v-center">
						<div>Servizi ecosistemici</div>
						<div class="small-font value">{{ boardLevelName(choice.ecosystem_services) }}</div>
					</div>
					<div class="level flex space v-center">
						<div>Risorse economiche a breve termine (costi)</div>
						<div class="small-font value">{{ boardLevelName(choice.short_economic_availability) }}</div>
					</div>
					<div class="level flex space v-center">
						<div>Risorse economiche a lungo termine (mantenimento)</div>
						<div class="small-font value">{{ boardLevelName(choice.long_economic_availability) }}</div>
					</div>
					<div class="level flex space v-center">
						<div>Benessere dei cittadini</div>
						<div class="small-font value">{{ boardLevelName(choice.citizens_wellbeing_perception) }}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="timer uppercase">
			<div v-if="!disable_choice">
				<div>TEMPO PER ESEGUIRE L’AZIONE</div>
				<div class="medium-font">
					<span v-if="timer.pause == null">{{ readableTime((timer.end ) - current_time) }}</span>
					<span v-else>{{ readableTime(timer.end - timer.pause ) }} (Pausa)</span>
				</div>
			</div>
			
			<div v-else>
				Tempo per effettuare la scelta esaurito
			</div>
		</div>
		
		<div class="continue button arrow" v-if="inputs" :class="{disabled : (disable_choice || selected_choice == null), loading : sending_choice}" @click="sendChoice">Continua</div>
		
		<div class="choice-done" v-if="current_choice && current_choice.choice_data.choice != 0">
			<div class="main-column">
				<div class="content text-center">
					<div>
						GLI ALTRI GIOCATORI<br>
						STANNO SCEGLIENDO<br>
						QUALE AZIONE ESEGUIRE.<br>
						<br>
						ATTENDI ANCORA QUALCHE<br>
						ISTANTE PER PROCEDERE…<br>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';
	import axios from 'axios';

	export default {
		name: 'ChoiceManager',
		mixins : [helperMixin, gameMixin],
		props: ['has_leader', 'inputs'],
		data() {
			return {
				sending_choice : false,
				selected_choice : null,
				toggled_choice : null
			};
		},
		computed: {
			not_leader(){
				if(!this.has_leader || this.current_choice == null) return false;
				return this.current_choice.user_data.leader == '0';
			},
			disable_choice(){
				return (this.timer.end - this.current_time < 0 && this.timer.pause == null);
			}
		},
		methods : {
			toggleChoice(choice_id){
				if(this.toggled_choice != choice_id){
					this.toggled_choice = choice_id;
				}else{
					this.toggled_choice = null;
				}
			},
			sendChoice(){
				if(this.selected_choice == null) return;
				this.sending_choice = true;
				
				var data = { 
					user_id : this.user_id, 
					choice : this.selected_choice
				}
				
				axios.post(this.api_url+'send_choice', data)
					.then( (response) => {	
						if(response.status == 200 && response.data.success){
							this.$store.commit('setCurrentChoice', this.selected_choice);
						}
						
						this.selected_choice = null;
						this.toggled_choice = null;
						this.sending_choice = false;
					});
			},
		},
	}
</script>	

<style lang="scss">
	@import "@/assets/styles/variables.scss";

	.choice-manager{
		margin-bottom: 40px;
		
		.choices{
			margin-top: 10px;
			
			.choice{
				margin-bottom: 5px;
				padding-bottom: 5px;
				border-bottom: 1px solid black;
				
				&:last-child{
					border-bottom: none;
				}
			
				label{
					cursor: pointer;
					width: 100%;
				}
				input{
					width: 28px;
					height: 28px;
					border: 1px solid black;
					flex-shrink: 0;
					margin-right: 10px;
					cursor: pointer;
				}
				.toggle{
					position: relative;	
					width: 28px;
					height: 28px;
					flex-shrink: 0;
					cursor: pointer;
					
					div{
						left: 3px;
						top: 3px;
						width: 20px;
						height: 20px;
						position: absolute;
						border-top: 2px solid black;
						border-right: 2px solid black;
						transform: rotate(45deg);
					}
				}
				.toggled-content{
					margin-top: 5px;
					padding-top: 10px;
					padding-bottom: 5px;
					border-top: 1px solid black;
					
					.level{
						height: 45px;
						line-height: 1;
						
						.value{
							margin-left: 10px;
							color: $red;
							white-space: nowrap;
							text-transform: uppercase;
						}
					}
				}
				
				&.toggled{
					.toggle div{
						transform: rotate(135deg);
					}
				}
			}
			
			
			&.not-leader label{
				pointer-events: none;
				
				input{
					display: none;
				}
			}
		}
	
		.timer{
			position: absolute;
			left: 0;
			width: 100%;
			bottom: 0;
			background-color: $red;
			color: white;
			border-radius: 30px;
			text-align: center;
			padding: 20px;	
		}
		
		.continue{
			position: absolute;
			top: 100%;
			right: 0;
			margin-top: 20px;
		}
	}
	
	.choice-done{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
		z-index: 10;
		
		.content{
			width: 100%;
			padding: 25px;
			background-color: white;
			color: $red;
			border-radius: 20px;
		}
	}
</style>