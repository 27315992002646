<template>
	<div class="disaster-stage">
		<div class="main-column">
			<div class="content sole-serif">
				<div class="header">
					<h1 class="text-center rotunda-veneta">Life Beware Herald</h1>
					
					<hr>
					
					<div class="date">{{date}}</div>
				</div>
				
				<hr>
				
				<div class="message text-center">
					<div v-if="superuser">
						<div v-if="stage == 'first_disaster'">Questa è la schermata che riporta le conseguenze della prima catastrofe, lascia qualche minuto ai giocatori per comprendere le statistiche, poi premi <strong class="red-txt">CONTINUA</strong> per passare all’attività successiva e continuare la partita.</div>
						<div v-if="stage == 'last_disaster'">Questa è la schermata che riporta le conseguenze del disastro finale della partita, premi <strong class="red-txt">CONTINUA</strong> per andare alla schermata finale con il risultato della partita.</div>
					</div>
					
					<div v-else class="medium-font small-row-padding ">
						{{disaster.name}}
					</div>
				</div>
				
				<hr>
				
				<boards/>
				
				<div v-if="superuser" class="message text-center">
					<div class="button arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Boards from '@/components/Boards.vue'
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Disaster',
		mixins : [helperMixin, gameMixin],
		components: {
			Boards
		},
	}
</script>	

<style lang="scss">
	
</style>