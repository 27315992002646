<template>
	<div class="end-stage">
		<div class="main-column">
			<div class="content sole-serif">
				<div class="header">
					<h1 class="text-center rotunda-veneta">Life Beware Herald</h1>
					
					<hr>
					
					<div class="date">{{date}}</div>
				</div>
				
				<hr>
				
				<div class="message text-center">
					<div class="medium-font">
						Come va la vita a Life Beware Valley dopo 6 mesi?
						<br><br>
						Il disastro è ormai un ricordo del passato?
					</div>
				</div>
				
				<hr>
				
				<boards/>
				
				<div v-if="superuser" class="message text-center">
					<div class="button arrow" :class="{loading : advancing_phase}" @click="advancePhase">Continua</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Boards from '@/components/Boards.vue'
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'End',
		mixins : [helperMixin, gameMixin],
		components: {
			Boards
		},
	}
</script>	

<style lang="scss">
	.work-stage{
		
	}
</style>