<template>
	<div class="personal-stage">
		<div class="main-column">
			<div class="content fulmar">
				<div v-if="superuser">
					<timer-manager />
				</div>
				
				<div v-else>
					<div class="header colored">
						<h1 class="text-center">Life Beware Bank</h1>
						
						<hr>
						
						<div class="text-center">We Always Pranch by your side</div>
						
						<hr>
					</div>
					
					<div class="message text-center">
						<div class="medium-font">Puoi fare bene alla città anche nel tuo piccolo!</div>
						<hr>
						<div>Ogni scelta influirà sulle statistiche della tua sfera privata e sulla sfera pubblica. Una volta scelto, prima di confermare la tua azione, potrai visualizzare l’influenza sulle statistiche.</div>
					</div>
					
					<hr>
					
					<choice-manager
						:has_leader="false"
						:inputs="true"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimerManager from '@/components/TimerManager.vue'
	import ChoiceManager from '@/components/ChoiceManager.vue'
	import { helperMixin } from '@/store/helper.js';
	import { gameMixin } from '@/store/game_mixin.js';

	export default {
		name: 'Personal',
		mixins : [helperMixin, gameMixin],
		components: {
			TimerManager, ChoiceManager
		},
	}
</script>	

<style lang="scss">
	.personal-stage{
		
	}
</style>